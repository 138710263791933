import React, { useState } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import QreditSwapABI from './QreditSwapABI.json';
import './index.css'
import { HiClipboardCopy } from 'react-icons/hi';
const qreditSwapAddress = '0x9ad97d42a4238a6927a346701a85113278a69d80'; 


interface Props {
  text: string;
}

const CopyableText: React.FC<Props> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <a
      className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium bg-black/30 text-red-400 mx-2 hover:cursor-pointer  hover:bg-black/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={copyToClipboard}
    >
      <HiClipboardCopy className="w-4 h-4 mr-2" />
      {copied ? 'Copied!' : text}
    </a>
  );
};

function App() {
  const [txId, setTxId] = useState('');
  const [loading, setLoading] = useState(false);
  const [claimStatus, setClaimStatus] = useState<'success' | 'error' | null>(null);
  const [error, setError] = useState('');
  const [showContent, setShowContent] = useState(false);


  function disconnectFromMetamask() {
    window.ethereum.disconnect();
    setProvider(null);
  }

  const handleNextClick = () => {
    setShowContent(true);
  }
  // Handle form submission
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate the transaction id using the Qredit API
    const qreditTxData = await validateTransactionId(txId);

    if (
      !qreditTxData ||
      qreditTxData.recipient !== 'XQeBkQjJSvGvo7N6T1Lr7RpQkmfRVF15cy' ||
      qreditTxData.version !== 2 ||
      qreditTxData.type !== 0 ||
      qreditTxData.typeGroup !== 1 ||
      !Web3.utils.isAddress(qreditTxData.vendorField)
    ) {
      setError('Invalid transaction id');
      return;
    }

    // Make sure the user has connected their wallet
    if (!window.ethereum.selectedAddress) {
      try {
        await window.ethereum.enable();
      } catch (e) {
        setError('Please connect your wallet');
        return;
      }
    }

    // Create a new web3 instance using the user's provider
    const web3 = new Web3(window.ethereum);

    // Create a new contract instance using the QreditSwap ABI and address
    const qreditSwap = new web3.eth.Contract(QreditSwapABI as any, qreditSwapAddress);

    // Set loading state to true
    setLoading(true);

    // Call the claim function on the contract
    try {
      await qreditSwap.methods.claim(txId).send({
        from: window.ethereum.selectedAddress,
        value: web3.utils.toWei('0.01', 'ether')
    });
    console.log("0.0.1 BNB")
      setClaimStatus('success');
    } catch (e) {
      setError('There was an error processing your claim');
      setClaimStatus('error');
    } finally {
      setLoading(false);
    }
  };

  // Helper function to validate the transaction id using the Qredit API
  const validateTransactionId = async (txId: string) => {
    try {
      const response = await axios.get(`https://qredit.cloud/api/v2/transactions/${txId}`);
      return response.data.data;
    } catch (e) {
      return null;
    }
  };

  // Render the claim form or the claim status message depending on the state
  const renderContent = () => {
    if (claimStatus === 'success') {
      return (
        <div className="bg-green-100/10 text-center font-bold text-green-300 px-4 py-6 my-8 rounded relative" role="alert">
          <strong className="font-bold">Congratulations!</strong>
          <span className="block sm:inline"> Your tokens have been claimed successfully. It may take some minutes before you see them in your balance.</span>
        </div>
      );
    } else if (claimStatus === 'error') {
      return (
        <div className="bg-red-100/10 text-center font-bold text-red-300 px-4 py-6 my-8 rounded relative" role="alert">
          <strong className="font-bold">Oops!</strong>
          <span className="block sm:inline"> {error}</span>
          </div>
  );
} else {
  return (
    <form onSubmit={handleFormSubmit} className="py-16">
      <div>
        <label className="block text-gray-300 text-xl font-bold mb-2 text-center" htmlFor="txId">
          Paste here the ID of your swap transaction on Qredit blockchain
        </label>
        <input
          className="shadow appearance-none border border-[#03132d]/30 bg-[#03132d]/40 rounded w-full py-3 px-3 text-white font-bold leading-tight focus:outline-none focus:shadow-outline mt-4 text-center"
          id="txId"
          type="text"
          value={txId}
          onChange={(event) => setTxId(event.target.value)}
        />
      </div>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div className="mt-4 text-center py-6">
        <button
          className="bg-[#a82431] hover:bg-[#a82431]/80 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline py-3 font-Sora text-xl"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Claim'}
        </button>
      </div>
    </form>
  );
}
};

return (
  <div className="max-w-4xl mx-auto mt-16 text-white  px-16 pt-16 ">
    {/* <img src="https://explorer.qredit.io/img/header/logo-navbar-light.png" alt="" className='my-8 h-12' /> */}
    <div className='rounded'>
      <img src="/bg.jpg" className='absolute -z-10 right-0 top-0' alt="" />
      
      {!showContent && (
        <div>
         <h1 className="text-6xl font-bold mb-4 text-blue font-Sora text-center">Welcome to Qredit Swap!</h1>
         <div>
         <div className="max-w-4xl mx-auto px-4 py-8 text-center">
  <p className="text-2xl font-bold font-Sora mb-4 text-gray-300">These are the steps to swap your XQR coins.</p>
  <ol className="list-decimal  pl-4 text-xl space-y-6 text-left font-Sora">
    <li className="mb-2 rounded-lg p-2 marker:text-[#a82431] bg-black/30">
    Go to <a href="https://github.com/Qredit/desktop-wallet/releases" className="text-red-600  hover:underline hover:text-red-800 focus:outline-none focus:text-red-800 ">this</a> website and download the Qredit Desktop Wallet.
    </li>
    <li className="mb-2 rounded-lg p-2 marker:text-[#a82431] bg-black/30">
      Install MetaMask and create an address for Binance Smart Chain. You can find instructions for this process <a href="https://www.coingecko.com/learn/how-to-add-binance-smart-chain-bsc-to-metamask" className="text-red-600 hover:underline hover:text-red-800 focus:outline-none focus:text-red-800">here</a>.
    </li>
    <li className="mb-2 rounded-lg p-2 marker:text-[#a82431] bg-black/30">
      Send your XQR coins to this address: <CopyableText text="XQeBkQjJSvGvo7N6T1Lr7RpQkmfRVF15cy"/> , and add your new BSC address in the memo field.
    </li>
    <li className="mb-2 rounded-lg p-2 marker:text-[#a82431] bg-black/30">
      Wait for 51 confirmations of your transaction, and then enter your transaction ID in the form provided.
    </li>
    <li className="mb-2 rounded-lg p-2 marker:text-[#a82431] bg-black/30">
      Make sure to have some Binance Coin (BNB) in your wallet to cover the transaction fees.
    </li>
  </ol>
</div>
         </div>
        <div className='mx-auto w-fit'>
        <button onClick={handleNextClick} className='bg-[#a82431] hover:bg-[#a82431]/80 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline py-3 font-Sora text-xl '>
          Let's start!
        </button>
        </div>
       </div>
      )}
      {showContent &&
      <div>
        <h1 className="text-7xl font-bold mb-4 text-blue font-Sora text-center">Swap your XQR</h1>
        { renderContent()}
      </div>
     }
    </div>
<div className="flex">
<div className="flex items-center hover:underline hover:cursor-pointer">
  <img src="/telegram.png" alt="Telegram Icon" className="h-6 w-6 mr-2" />
  <div className="text-white">Questions? Join us on Telegram</div>
</div>
</div>
    <div className='text-center mt-4 text-sm'><a href="https://qredit.io/" className='hover:underline'>Qredit 2023 - 461654654</a></div>
  </div>
);
}

export default App;